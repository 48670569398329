// React
import { useState, useEffect } from 'react'

// Next
import Link from 'next/link'
import { useRouter } from 'next/router'

// Components
import Logo from '@/components/blocks/logo'
import { Footer } from '@/components/layouts/footer'
import { Button } from '@/components/ui/button'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover'

// Utils
import { useSession } from '@/utils/auth/user-context'

// External
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from '@headlessui/react'
import {
  ChevronDown,
  MenuIcon,
  RocketIcon,
  SendIcon,
  XIcon,
} from 'lucide-react'
import { useTranslation } from 'next-i18next'

type LayoutProps = {
  children: React.ReactNode
}

const Header = () => {
  const [featuresOpen, setFeaturesOpen] = useState(false)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const session = useSession()
  const { t } = useTranslation(['common'])

  // Prevent flash of untranslated content
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])

  if (!mounted) {
    return <div className='h-16 border-b border-gray-200 bg-white' /> // Placeholder with same height
  }

  const features = [
    {
      name: t('layouts.landingLayout.features.textToMeme.name'),
      description: t('layouts.landingLayout.features.textToMeme.description'),
      href: '/text-to-meme',
    },
    {
      name: t('layouts.landingLayout.features.themedMemes.name'),
      description: t('layouts.landingLayout.features.themedMemes.description'),
      href: '/search',
    },
    {
      name: t('layouts.landingLayout.features.ownTemplates.name'),
      description: t('layouts.landingLayout.features.ownTemplates.description'),
      href: '/meme-maker',
    },
    {
      name: t('layouts.landingLayout.features.memeTemplates.name'),
      description: t(
        'layouts.landingLayout.features.memeTemplates.description'
      ),
      href: '/meme-templates',
    },
    {
      name: t('layouts.landingLayout.features.gifMemes.name'),
      description: t('layouts.landingLayout.features.gifMemes.description'),
      href: '/gif-memes',
    },
    {
      name: t('layouts.landingLayout.features.multilingualMemes.name'),
      description: t(
        'layouts.landingLayout.features.multilingualMemes.description'
      ),
      href: '/text-to-meme',
    },
    {
      name: t('layouts.landingLayout.features.memeApis.name'),
      description: t('layouts.landingLayout.features.memeApis.description'),
      href: '/meme-api',
    },
    {
      name: t('layouts.landingLayout.features.memeMaker.name'),
      description: t('layouts.landingLayout.features.memeMaker.description'),
      href: '/meme-maker',
    },
    {
      name: t('layouts.landingLayout.features.savedMemes.name'),
      description: t('layouts.landingLayout.features.savedMemes.description'),
      href: '/account/saved-memes',
    },
  ]

  return (
    <header className='border-b border-gray-200 bg-white'>
      <nav
        aria-label='Global'
        className='mx-auto flex items-center justify-between px-4 py-2 lg:px-8'
      >
        <div className='flex lg:flex-1'>
          <Logo />
        </div>
        <div className='flex lg:hidden'>
          <button
            type='button'
            onClick={() => setMobileMenuOpen(true)}
            className='-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700'
          >
            <span className='sr-only'>Open main menu</span>
            <MenuIcon aria-hidden='true' className='h-6 w-6' />
          </button>
        </div>

        <div className='hidden lg:flex lg:gap-x-4'>
          <Button variant='link' className='text-base font-semibold' asChild>
            <Link href='/pricing'>
              {t('layouts.landingLayout.navbar.buttons.pricing')}
            </Link>
          </Button>

          <Popover open={featuresOpen} onOpenChange={setFeaturesOpen}>
            <PopoverTrigger asChild>
              <Button variant='link' className='text-base font-semibold'>
                <span>
                  {t('layouts.landingLayout.navbar.buttons.features')}
                </span>
                <ChevronDown className='ml-2 h-5 w-5' />
              </Button>
            </PopoverTrigger>

            <PopoverContent className='w-full max-w-3xl'>
              <div className='grid grid-cols-1 p-4 lg:grid-cols-3'>
                {features.map((item) => (
                  <div
                    key={item.name}
                    className='group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50'
                  >
                    <div className='flex-auto'>
                      <Link
                        href={item.href}
                        className='block font-semibold text-gray-900'
                        onClick={() => setFeaturesOpen(false)}
                      >
                        {item.name}
                        <span className='absolute inset-0' />
                      </Link>
                      <p className='mt-1 text-gray-600'>{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverContent>
          </Popover>

          <Button variant='link' className='text-base font-semibold' asChild>
            <Link href='/meme-marketing'>
              {t('layouts.landingLayout.navbar.buttons.memeMarketingGuide')}
            </Link>
          </Button>

          <Button variant='link' className='text-base font-semibold' asChild>
            <Link href='https://supermeme.ai/blog'>
              {t('layouts.landingLayout.navbar.buttons.blog')}
            </Link>
          </Button>

          <Button variant='link' className='text-base font-semibold' asChild>
            <Link href='/meme-api'>
              {t('layouts.landingLayout.navbar.buttons.api')}
            </Link>
          </Button>
        </div>

        <div className='hidden lg:flex lg:flex-1 lg:justify-end'>
          {!!session ? (
            <Button variant='default' asChild>
              <Link href='/text-to-meme'>
                <RocketIcon className='mr-2 h-4 w-4' />
                {t('layouts.landingLayout.navbar.buttons.dashboard')}
              </Link>
            </Button>
          ) : (
            <Button variant='default' asChild>
              <Link href='/login'>
                <SendIcon className='mr-2 h-4 w-4' />
                {t('layouts.landingLayout.navbar.buttons.login')}
              </Link>
            </Button>
          )}
        </div>
      </nav>

      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className='lg:hidden'
      >
        <div className='fixed inset-0 z-10' />
        <DialogPanel className='fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10'>
          <div className='flex items-center justify-between'>
            <Logo />
            <button
              type='button'
              onClick={() => setMobileMenuOpen(false)}
              className='-m-2.5 rounded-md p-2.5 text-gray-700'
            >
              <span className='sr-only'>Close menu</span>
              <XIcon aria-hidden='true' className='h-6 w-6' />
            </button>
          </div>
          <div className='mt-6 flow-root'>
            <div className='-my-6 divide-y divide-gray-500/10'>
              <div className='space-y-2 py-6'>
                <Link
                  href='/pricing'
                  className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('layouts.landingLayout.navbar.buttons.pricing')}
                </Link>

                <Disclosure as='div' className='-mx-3'>
                  <DisclosureButton className='group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'>
                    {t('layouts.landingLayout.navbar.buttons.features')}
                    <ChevronDown
                      aria-hidden='true'
                      className='h-5 w-5 flex-none group-data-[open]:rotate-180'
                    />
                  </DisclosureButton>
                  <DisclosurePanel className='mt-2 space-y-2'>
                    {features.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className='block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </DisclosurePanel>
                </Disclosure>

                <Link
                  href='/meme-marketing'
                  className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('layouts.landingLayout.navbar.buttons.memeMarketingGuide')}
                </Link>

                <Link
                  href='https://supermeme.ai/blog'
                  className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('layouts.landingLayout.navbar.buttons.blog')}
                </Link>

                <Link
                  href='/meme-api'
                  className='-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50'
                  onClick={() => setMobileMenuOpen(false)}
                >
                  {t('layouts.landingLayout.navbar.buttons.api')}
                </Link>
              </div>
              <div className='py-6'>
                {!!session ? (
                  <Button variant='default' asChild className='w-full'>
                    <Link href='/text-to-meme'>
                      <RocketIcon className='mr-2 h-4 w-4' />
                      {t('layouts.landingLayout.navbar.buttons.dashboard')}
                    </Link>
                  </Button>
                ) : (
                  <Button variant='default' asChild className='w-full'>
                    <Link href='/login'>
                      <SendIcon className='mr-2 h-4 w-4' />
                      {t('layouts.landingLayout.navbar.buttons.login')}
                    </Link>
                  </Button>
                )}
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}

const LandingLayout: React.FC<LayoutProps> = ({ children }) => {
  const router = useRouter()
  return (
    <div>
      <div>
        <Header />
      </div>
      <main className='min-h-screen px-2 md:px-8'>{children}</main>
      {router.pathname !== '/login' && <Footer />}
    </div>
  )
}

export { LandingLayout }
